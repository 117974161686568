import React, { FC } from "react";
import styled from "styled-components";
import { cssEaseInOutCirc } from "@theme/easing";
import { Icon } from "@iconify/react";
import { arrowLeftIcon, arrowRightIcon } from "@assets/icons";

const StyledArrowButton = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.primaryDarker};
  cursor: pointer;
  transition: color 0.5s ${cssEaseInOutCirc};
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${({ theme }) => theme.foreground};
  }

  :active {
    transform: scale(0.9);
  }
`;

const ArrowButton: FC<Props> = ({ direction = "left", ...props }) => (
  <StyledArrowButton {...(props as any)}>
    <Icon icon={direction === "left" ? arrowLeftIcon : arrowRightIcon} />
  </StyledArrowButton>
);

interface Props extends React.HTMLProps<HTMLButtonElement> {
  direction?: "left" | "right";
}

export default ArrowButton;
