import BasicTemplate from "@templates/BasicTemplate";
import React, { FC } from "react";
import Hero from "@components/molecules/Hero/Hero";
import AboutEstate from "@components/molecules/AboutEstate/AboutEstate";
import Visualizations from "@components/molecules/Visualizations/Visualizations";
import Shape from "@components/molecules/Shape/Shape";
import Interior from "@components/molecules/Interior/Interior";
import Properties from "@components/molecules/Properties/Properties";
import InteractiveVisualization from "@components/molecules/InteractiveVisualization/InteractiveVisualization";
import AboutUs from "@components/molecules/AboutUs/AboutUs";
import Contact from "@components/molecules/Contact/Contact";
import Location from "@components/molecules/Location/Location";
import { Location as LocationType } from "../types/location";

const Index: FC<{ location: LocationType }> = ({ location }) => (
  <BasicTemplate header={() => <Hero />} location={location}>
    <AboutEstate />
    <Visualizations />
    <Shape />
    <Interior />
    <Properties />
    <Location />
    <InteractiveVisualization />
    <AboutUs />
    <Contact />
  </BasicTemplate>
);

export default Index;
