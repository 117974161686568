import React, { FC } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { easeOutCirc } from "@theme/easing";
import { Video } from "gatsby-video";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledOverlay = styled(motion.div)<{ $background: string }>`
  position: absolute;
  left: -1px;
  top: -1px;
  width: 101%;
  height: 101%;
  background: ${({ theme, $background }) => $background || theme.background};
  z-index: 2;
  transform-origin: top left;
`;

const AnimatedImage: FC<Props> = ({
  className,
  background,
  autoPlay,
  poster,
  loop,
  muted,
  sources,
  ...props
}) => {
  const { ref, inView } = useInView({ threshold: 0.25 });

  const videoProps = { autoPlay, poster, loop, muted, sources, playsInline: true };

  return (
    <StyledWrapper className={className} ref={ref} {...props}>
      <StyledOverlay
        animate={inView && { scaleY: 0 }}
        transition={{ duration: 1.5, ease: easeOutCirc }}
        $background={background}
      />
      <Video {...videoProps} />
    </StyledWrapper>
  );
};

interface Props {
  className?: string;
  background?: string;
  poster?: string;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  sources: any[];
}

export default AnimatedImage;
