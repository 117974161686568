import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import AnimatedImage from "@components/atoms/AnimatedImage/AnimatedImage";
import { getImage } from "gatsby-plugin-image";
import BusinessLogo from "@components/atoms/BusinessLogo/BusinessLogo";
import { cssEaseOutExpo } from "@theme/easing";
import { ChildImageSharp } from "../../../types/queryTypes";

const StyledWrapper = styled.section`
  max-width: 1460px;
  width: 90%;
  padding-top: 180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 700px;
    padding-top: 90px;
  }
`;

const StyledLeftColumn = styled.div`
  @media (max-width: 1340px) {
    width: 400px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledHeadline = styled.h2`
  margin: 0 0 40px;
  white-space: nowrap;

  @media (max-width: 1340px) {
    white-space: unset;
  }

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const StyleSecondHeadline = styled.h3`
  margin: 0 0 30px;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.3;

  span {
    color: ${({ theme }) => theme.secondary};
    display: block;
  }

  @media (max-width: 720px) {
    font-size: 2.4rem;
  }
`;

const StyledParagraph = styled.p`
  margin: 0 0 25px;
  line-height: 1.8;
  max-width: 540px;

  :last-of-type {
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.secondary};
  }

  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

const StyledBusinessLogoLink = styled.a`
  display: block;
  margin-top: 25px;
  width: 100%;

  @media (max-width: 1024px) {
    margin: 40px 0 0;
  }
`;

const StyledBusinessLogo = styled(BusinessLogo)`
  width: 100%;
  height: auto;
`;

const StyledLink = styled.a`
  padding: 20px 30px;
  border: 1px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  transition: color 0.5s ${cssEaseOutExpo}, background 0.5s ${cssEaseOutExpo};

  :hover {
    background: ${({ theme }) => theme.secondary};
    color: #fff;
  }

  :active {
    transform: scale(0.9);
  }

  @media (max-width: 1024px) {
    margin: 40px auto;
    display: flex;
  }
`;

const StyledImage = styled(AnimatedImage)`
  width: 730px;
  height: 854px;
  margin-left: 100px;

  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    height: auto;
    margin-top: 40px;
  }
`;

const AboutUs = () => {
  const { file } = useStaticQuery<{ file: ChildImageSharp }>(query);

  return (
    <StyledWrapper id="about_us">
      <StyledLeftColumn>
        <StyledHeadline data-scroll="">Poznaj dewelopera</StyledHeadline>
        <StyleSecondHeadline data-scroll="">
          Solidny i sprawdzony deweloper - <span>BGK Investment</span>
        </StyleSecondHeadline>

        <StyledParagraph data-scroll="">
          <span>Bgk Investment</span> jest niezawodnym partnerem dla wszystkich
          szukających własnego mieszkania. Niezależnie od wielkości projektu i
          jego zakresu. Wykonujemy nasze prace zgodnie z obietnicami i
          założeniami, przestrzegając terminów i zawsze spełniając{" "}
          <span>standardy jakości</span>.
        </StyledParagraph>

        <StyledParagraph data-scroll="">
          W <span>branży budowlanej</span> od wielu lat pracujemy na rzecz
          rozwoju i tworzenia lepszej przyszłości dla lokalnych mieszkańców.
          Zrealizowaliśmy szeroką gamę projektów od małych osiedli
          mieszkaniowych po duże kompleksy mieszkaniowe oraz użyteczności
          publicznej zarówno w <span>Mławie</span> jak i innych miastach{" "}
          <span>w Polsce</span>.
        </StyledParagraph>

        <StyledParagraph data-scroll="">
          Dlatego jeśli potrzebujesz wysokiej jakości domu w przystępnej cenie,{" "}
          <u>skontaktuj się z nami!</u>
        </StyledParagraph>

        <StyledBusinessLogoLink
          href="https://bgkinvestment.pl/"
          rel="noopener"
          target="_blank"
          aria-label="Strona dewelopera"
          data-scroll=""
        >
          <StyledBusinessLogo />
        </StyledBusinessLogoLink>

        <div data-scroll="">
          <StyledLink
            href="https://bgkinvestment.pl/"
            rel="noopener"
            target="_blank"
          >
            Strona dewelopera
          </StyledLink>
        </div>
      </StyledLeftColumn>

      <StyledImage
        image={getImage(file.childImageSharp)}
        alt=""
        data-scroll=""
      />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "about_us" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
        )
      }
    }
  }
`;

export default AboutUs;
