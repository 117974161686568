import React from "react";
import styled from "styled-components";
import Overlay from "@assets/svg/overlay.svg";
import Gallery from "@components/molecules/Visualizations/Gallery/Gallery";

const StyledWrapper = styled.section`
  padding: 240px 0 0;
  position: relative;

  @media (max-width: 1024px) {
    padding: 120px 0 0;
  }

  @media (max-width: 720px) {
    padding: 80px 0 0;
  }
`;

const StyledBackground = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  background: #f4f4f4;
  width: 100%;
  height: 520px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledOverlay = styled(Overlay)`
  width: 100%;
  height: 100%;
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeadline = styled.h2`
  margin: 0;
`;

const StyledGallery = styled(Gallery)`
  margin-top: 50px;

  @media (max-width: 720px) {
    margin-top: 30px;
  }
`;

const Visualizations = () => (
  <StyledWrapper id="visualizations">
    <StyledBackground>
      <StyledOverlay />
    </StyledBackground>
    <StyledInnerWrapper>
      <StyledHeadline data-scroll="">Galeria wizualizacji</StyledHeadline>
      <StyledGallery />
    </StyledInnerWrapper>
  </StyledWrapper>
);

export default Visualizations;
