import React, { FC, useState } from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { markerIcon } from "@assets/icons";
import { cssEaseOutExpo } from "@theme/easing";
import AnimatedImage from "@components/atoms/AnimatedImage/AnimatedImage";
import { getImage } from "gatsby-plugin-image";
import LocationBlock from "@components/atoms/LocationBlock/LocationBlock";
import StageCarriage from "@assets/svg/stage_carriage.svg";
import Playground from "@assets/svg/playground.svg";
import Environment from "@assets/svg/environment.svg";
import Neighbourhood from "@assets/svg/neighbourhood.svg";
import Button from "@components/atoms/Button/Button";
import Switch from "@components/atoms/Switch/Switch";
import { ChildImageSharp } from "../../../types/queryTypes";

const StyledWrapper = styled.div`
  padding: 120px 0;
  margin: 0 auto;
  width: 90%;
  max-width: 1480px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    max-width: 700px;
    padding: 120px 0 60px;
  }

  @media (max-width: 720px) {
    padding: 60px 0;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const StyledHeadline = styled.h2`
  max-width: 540px;
  line-height: 1.3;
  margin: 0 0 100px 0;

  span {
    position: relative;

    ::before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 12px;
      background: ${({ theme }) => theme.foreground};
      height: 4px;
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 0 40px;
    text-align: center;
    max-width: 700px;
  }

  @media (max-width: 720px) {
    margin: 0 0 20px;

    span {
      ::before {
        bottom: 6px;
        height: 2px;
      }
    }
  }
`;

const StyledRightColumn = styled.div`
  max-width: 550px;
  margin-left: 40px;

  @media (max-width: 1024px) {
    margin-left: 0;
    max-width: unset;
  }
`;

const StyledParagraph = styled.p`
  line-height: 1.8;

  span {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledStatsWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledStat = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (max-width: 720px) {
    margin-bottom: 40px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledStatNumber = styled.div`
  font-family: "Cardo", serif;
  font-size: 2rem;

  span {
    font-size: 7.2rem;
  }
`;

const StyledStatHeadline = styled.h3`
  font-weight: 400;
  font-size: 1.6rem;
  margin: 10px 0 0;
`;

const StyledInfographicWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-right: auto;
`;

const StyledInfographicInnerWrapper = styled.div`
  display: grid;
  width: 100%;
`;

const StyledImage = styled(AnimatedImage)<{ $isActive: boolean }>`
  width: 100%;
  grid-column: 1;
  grid-row: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ${cssEaseOutExpo};

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

const StyledSwitch = styled(Switch)`
  margin-top: 40px;
`;

const StyledBlocksWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1150px;
  justify-content: center;
  margin: 0 -50px 0 0;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }

  @media (max-width: 720px) {
    margin: 0;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const StyledLocationBlock = styled(LocationBlock)`
  margin-right: 50px;
  margin-top: 60px;

  @media (max-width: 720px) {
    margin-right: 0;
  }

  @media (max-width: 480px) {
    margin-top: 40px;
  }
`;

const Location: FC = () => {
  const [infographicType, setInfographicType] = useState<0 | 1>(1);
  const { infographicMap, infographicVis } = useStaticQuery<{
    infographicVis: ChildImageSharp;
    infographicMap: ChildImageSharp;
  }>(query);

  return (
    <StyledWrapper id="location">
      <StyledContentWrapper>
        <StyledHeadline data-scroll="">
          <span>Lokalizacja</span>, czyli wszędzie blisko
        </StyledHeadline>
        <StyledRightColumn>
          <StyledParagraph data-scroll="">
            <span>Lewiczyn</span> to mała wioska, w której przy dźwiękach ciszy
            i filiżance herbaty można cieszyć się własnym mieszkaniem. Wieś
            istnieje od setek lat. <span>Ma swoją historię i tradycję</span>.
            Stare drewniane domy po tylu latach nadal stoją w dobrym stanie.
            Mieszkańcy to ciężko pracujący ludzie, którzy starają się żyć jak
            najlepiej w spokojnym miejscu otoczonym przyrodą.{" "}
            <span>Osiedle Zielone Wzgórza</span> to prawdziwy azyl dla{" "}
            <span>szukających ciszy i spokoju</span>.
          </StyledParagraph>

          <StyledStatsWrapper data-scroll="">
            <StyledStat>
              <StyledStatHeadline>Dojazd do nowej S7.</StyledStatHeadline>
              <StyledStatNumber>
                <span>15</span> minut
              </StyledStatNumber>
            </StyledStat>

            <StyledStat>
              <StyledStatHeadline>Centrum Mławy.</StyledStatHeadline>
              <StyledStatNumber>
                <span>10</span> minut
              </StyledStatNumber>
            </StyledStat>

            <StyledStat>
              <StyledStatHeadline>Zalew Ruda.</StyledStatHeadline>
              <StyledStatNumber>
                <span>3</span> minuty
              </StyledStatNumber>
            </StyledStat>
          </StyledStatsWrapper>
        </StyledRightColumn>
      </StyledContentWrapper>

      <StyledButton
        href="https://goo.gl/maps/WEi22arMFGczVFdk8"
        target="_blank"
        rel="noopener"
        data-scroll=""
        icon={markerIcon}
        forwardedAs="a"
      >
        Zobacz na Google Maps
      </StyledButton>

      <StyledInfographicWrapper data-scroll="" data-scroll-distance="200">
        <StyledInfographicInnerWrapper>
          <StyledImage
            image={getImage(infographicVis.childImageSharp)}
            alt=""
            $isActive={infographicType === 1}
          />
          <StyledImage
            image={getImage(infographicMap.childImageSharp)}
            alt=""
            $isActive={infographicType === 0}
          />
        </StyledInfographicInnerWrapper>

        <StyledSwitch
          firstText="Mapa"
          secondText="Wizualizacja"
          currentValue={infographicType}
          setCurrentValue={setInfographicType}
        />
      </StyledInfographicWrapper>

      <StyledBlocksWrapper data-scroll="">
        <StyledLocationBlock vector={StageCarriage}>
          Świetne połączenie komunikacyjne z Mławą
        </StyledLocationBlock>

        <StyledLocationBlock vector={Playground}>
          Niedaleko Przedszkole i Plac Zabaw
        </StyledLocationBlock>

        <StyledLocationBlock vector={Neighbourhood}>
          Przyjazna i spokojna okolica
        </StyledLocationBlock>

        <StyledLocationBlock vector={Environment}>
          Zielone otoczenie
        </StyledLocationBlock>
      </StyledBlocksWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    infographicVis: file(name: { eq: "infographic_vis" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }

    infographicMap: file(name: { eq: "infographic_map" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 90
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
        )
      }
    }
  }
`;

export default Location;
