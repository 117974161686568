import React, { FC } from "react";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { easeOutCirc } from "@theme/easing";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledOverlay = styled(motion.div)<{ $background: string }>`
  position: absolute;
  left: -1px;
  top: -1px;
  width: 101%;
  height: 101%;
  background: ${({ theme, $background }) => $background || theme.background};
  z-index: 2;
  transform-origin: top left;
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const AnimatedImage: FC<Props> = ({
  image,
  alt,
  className,
  background,
  ...props
}) => {
  const { ref, inView } = useInView({ threshold: 0.25 });

  return (
    <StyledWrapper className={className} ref={ref} {...props}>
      <StyledOverlay
        animate={inView && { scaleY: 0 }}
        transition={{ duration: 1.5, ease: easeOutCirc }}
        $background={background}
      />
      <StyledImage image={image} alt={alt} />
    </StyledWrapper>
  );
};

interface Props {
  image: IGatsbyImageData;
  alt: string;
  className?: string;
  background?: string;
}

export default AnimatedImage;
