import React, { FC } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 220px;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.primaryDarker};

  svg {
    margin-bottom: 15px;
  }

  @media (max-width: 720px) {
    font-size: 1.6rem;
    width: 220px;

    svg {
      width: 80px;
    }
  }
`;

const LocationBlock: FC<Props> = ({ vector: Vector, children, className }) => (
  <StyledWrapper className={className}>
    <Vector />
    {children}
  </StyledWrapper>
);

interface Props {
  vector: React.FC;
  className?: string;
}

export default LocationBlock;
