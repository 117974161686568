import React, { FC, useContext, useLayoutEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { cssEaseOutExpo } from "@theme/easing";
import { Icon } from "@iconify/react";
import { arrowLeftIcon, arrowRightIcon } from "@assets/icons";
import { ChildImageSharp } from "../../../../types/queryTypes";
import { LightboxContext } from "@context/index";
import { filterLightboxImages } from "@utils/filterLightboxImages";
import interior from "@components/molecules/Interior/Interior";

SwiperCore.use([Navigation]);

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: auto;
  position: relative;
  padding-bottom: 35px;
  overflow: hidden;

  .swiper {
    max-width: 100%;
    position: relative;
    overflow: hidden;
  }

  .swiper-wrapper {
    display: inline-flex;
  }

  .swiper-slide {
    transform: scale(0.8);
    opacity: 0.6;
    transition: transform 1s ${cssEaseOutExpo}, opacity 1s ${cssEaseOutExpo};
  }

  .swiper-slide-active {
    transform: scale(1);
    opacity: 1;
  }

  @media (max-width: 720px) {
    width: 90%;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 60.46vw;
  height: 33.9vw;
  max-width: 1161px;
  max-height: 651px;

  @media (max-width: 720px) {
    width: 100%;
    height: 60vw;
  }
`;

const StyledNavigation = styled.div`
  width: 264px;
  height: 70px;
  background: ${({ theme }) => theme.primary};
  color: #fff;
  display: flex;
  padding: 15px 40px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 720px) {
    width: 160px;
    height: 50px;
    padding: 10px 20px;
  }
`;

const StyledDivider = styled.span`
  display: block;
  height: 100%;
  width: 2px;
  background: #fff;
`;

const StyledButton = styled.button`
  color: #fff;
  font-size: 2.6rem;
  width: calc(50% - 2px);
  background: transparent;
  border: 0;
  cursor: pointer;

  :active {
    transform: scale(0.9);
  }

  @media (max-width: 720px) {
    font-size: 1.8rem;
  }
`;

const Gallery: FC<Props> = ({ className }) => {
  const { interiorLightbox } = useContext(LightboxContext);
  const { allFile, lightbox } = useStaticQuery<{
    allFile: { nodes: ChildImageSharp[] };
    lightbox: { nodes: ChildImageSharp[] };
  }>(query);

  useLayoutEffect(() => {
    interiorLightbox.setImages(
      filterLightboxImages(lightbox.nodes).map((lightboxImage) => ({
        src: lightboxImage,
        loading: "lazy",
        alt: "",
      }))
    );
  }, []);

  const onImageClick = (index: number) => {
    interiorLightbox.setCurrentImage(index);
    interiorLightbox.setLightboxActive(true);
  };

  const onSlideChange = (swiper: SwiperCore) => {
    interiorLightbox.setCurrentImage(swiper.realIndex);
  };

  return (
    <StyledWrapper className={className} data-scroll="">
      <Swiper
        grabCursor
        updateOnWindowResize
        centeredSlides
        loop
        loopedSlides={allFile.nodes.length}
        breakpoints={{
          721: {
            slidesPerView: "auto",
          },
        }}
        slidesPerView={1}
        speed={1000}
        navigation={{
          prevEl: "#interior-prev-button",
          nextEl: "#interior-next-button",
        }}
        onSwiper={(swiper) => interiorLightbox.setSwiper(swiper)}
        onSlideChange={onSlideChange}
      >
        {allFile.nodes.map((child, index) => (
          <SwiperSlide key={index}>
            <StyledImage
              image={getImage(child.childImageSharp)}
              alt=""
              onClick={() => onImageClick(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledNavigation>
        <StyledButton id="interior-prev-button" aria-label="Poprzednie zdjęcie">
          <Icon icon={arrowLeftIcon} />
        </StyledButton>
        <StyledDivider />
        <StyledButton id="interior-next-button" aria-label="Następne zdjęcie">
          <Icon icon={arrowRightIcon} />
        </StyledButton>
      </StyledNavigation>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    allFile(filter: { name: { regex: "/interior/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }

    lightbox: allFile(
      filter: { name: { regex: "/interior/" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90, formats: JPG, layout: FIXED)
        }
      }
    }
  }
`;

interface Props {
  className?: string;
}

export default Gallery;
