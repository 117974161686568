import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const StyledWrapper = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  font-size: 7rem;
`;

const StyledContent = styled.div`
  margin-top: 25px;
  text-align: center;
  font-weight: 500;
  line-height: 1.3;

  span {
    display: block;
  }

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;

const Property: FC<Props> = ({ icon, children }) => (
  <StyledWrapper>
    <StyledIcon icon={icon} />
    <StyledContent>{children}</StyledContent>
  </StyledWrapper>
);

interface Props {
  icon?: any;
}

export default Property;
