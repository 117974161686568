import React from "react";
import styled from "styled-components";
import Gallery from "./Gallery/Gallery";

const StyledWrapper = styled.section`
  margin: 260px auto 0;

  @media (max-width: 1024px) {
    margin: 120px auto 0;
  }

  @media (max-width: 720px) {
    margin: 80px auto 0;
  }
`;

const StyledHeadline = styled.h2`
  margin: 0;

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const StyledParagraph = styled.p`
  font-size: 2rem;
  line-height: 1.8;
  max-width: 480px;
  margin: 80px 0 0 130px;

  span {
    position: relative;

    ::before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      border-radius: 1px;
      height: 2px;
      content: "";
      background: ${({ theme }) => theme.foreground};
    }
  }

  @media (max-width: 1024px) {
    max-width: unset;
    text-align: center;
    margin: 30px 0 0;
  }

  @media (max-width: 720px) {
    font-size: 1.6rem;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  margin: 0 auto 70px auto;

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 700px;
  }

  @media (max-width: 720px) {
    margin: 0 auto 40px auto;
  }
`;

const Interior = () => (
  <StyledWrapper>
    <StyledContentWrapper>
      <StyledHeadline data-scroll="">Unikatowe wnętrze</StyledHeadline>
      <StyledParagraph data-scroll="">
        Sprawdź jak może wyglądać <span>Twoje</span> przyszłe mieszkanie
        wewnątrz
      </StyledParagraph>
    </StyledContentWrapper>

    <Gallery />
  </StyledWrapper>
);

export default Interior;
