/* eslint-disable no-restricted-properties */
import React, { FC, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AnimatedText, {
  AnimatedTextWrapper,
} from "@components/atoms/AnimatedText/AnimatedText";
import { MainContext } from "@context/index";
import ScrollBottomButton from "@components/molecules/Hero/ScrollBottomButton/ScrollBottomButton";
import { Icon } from "@iconify/react";
import facebookIcon from "@iconify/icons-la/facebook-f";
import instagramIcon from "@iconify/icons-la/instagram";
import {
  cssEaseInOutCirc,
  cssEaseOutExpo,
  easeInOutCirc,
  easingFunction,
} from "@theme/easing";
import { motion } from "framer-motion";
import ArrowButton from "@components/atoms/ArrowButton/ArrowButton";
import { ChildImageSharp } from "../../../types/queryTypes";

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh) * 100);
  display: flex;
  z-index: 2;
  align-items: center;
  position: relative;
`;

const StyledHeadlineWrapper = styled.div`
  width: 80%;
  max-width: 1400px;
  margin: auto;
`;

const StyledSliderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const StyledSliderNavigation = styled.div`
  position: absolute;
  width: 560px;
  height: 90px;
  background: #f4f4f4;
  bottom: 0;
  right: 0;
  padding: 35px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledSliderThumbsWrapper = styled.div`
  display: flex;
  width: 170px;
  justify-content: space-between;
`;

const StyledSliderThumb = styled.div<{ $isActive: boolean }>`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.washMedium};
  cursor: pointer;
  transition: color 0.5s ${cssEaseOutExpo};

  :hover {
    color: ${({ theme }) => theme.washHeavy};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.primary}!important;
      cursor: default;
    `};
`;

const StyledProgress = styled.div<{ $max: number; $current: number }>`
  width: 115px;
  height: 2px;
  background: ${({ theme }) => theme.washLighter};
  position: relative;

  ::before {
    width: 100%;
    height: 100%;
    content: "";
    background: ${({ theme }) => theme.primaryDarker};
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: left top;
    transform: ${({ $max, $current }) => `scaleX(${$current / $max})`};
    transition: transform 0.5s ${cssEaseInOutCirc};
  }
`;

const StyledSlider = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const StyledImagesWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: inline-flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  transition: transform 2s ${cssEaseOutExpo};
  filter: brightness(40%);
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
`;

const StyledArrowsWrapper = styled.div`
  display: flex;
`;

const StyledArrowButton = styled(ArrowButton)`
  :first-of-type {
    margin-right: 25px;
  }
`;

const StyledHeadline = styled.h1`
  font-weight: 700;
  font-size: 7.2rem;
  color: #fff;

  @media (max-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 720px) {
    font-size: 4.8rem;
  }

  @media (max-width: 440px) {
    font-size: 3.8rem;
  }
`;

const StyledAnimatedText = styled(AnimatedText)`
  span {
    color: ${({ theme }) => theme.primary};
  }
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 5%;
  bottom: 40px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledLink = styled(motion.a).attrs(() => ({
  target: "_blank",
  rel: "noopener",
}))`
  color: #fff;
  text-decoration: none;
  font-size: 3.4rem;
  transition: color 0.5s ${cssEaseInOutCirc};
  display: flex;
  justify-content: center;
  align-items: center;

  :first-of-type {
    margin-bottom: 20px;
    font-size: 3rem;
  }

  :hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledScrollBottomButton = styled(ScrollBottomButton)`
  @media (min-width: 1025px) and (max-width: 1440px) {
    display: none;
  }
`;

const Hero: FC = () => {
  const { isLoading, smoothScrollbar, isLoaderExited, sweetScroll } =
    useContext(MainContext);
  const [currentActive, setCurrentActive] = useState<number>(0);
  const { allFile, datoCmsSetting } = useStaticQuery<{
    allFile: { nodes: ChildImageSharp[] };
    datoCmsSetting: { facebook: string; instagram: string };
  }>(query);
  const [timeout, setTimeoutState] = useState<NodeJS.Timeout>();
  const count = allFile.nodes.length;

  const handlePrevClick = () => {
    clearTimeout(timeout);
    setCurrentActive((prevState) =>
      prevState === 0 ? count - 1 : prevState - 1
    );
  };

  const handleNextClick = () => {
    clearTimeout(timeout);
    setCurrentActive((prevState) =>
      prevState === count - 1 ? 0 : prevState + 1
    );
  };

  const handleThumbClick = (index: number) => {
    clearTimeout(timeout);
    setCurrentActive(index);
  };

  useEffect(() => {
    if (isLoaderExited) {
      const changeSlide = () => {
        if (currentActive === count - 1) {
          setCurrentActive(0);
        } else {
          setCurrentActive((prevState) => prevState + 1);
        }
      };

      setTimeoutState(setTimeout(changeSlide, 8000));
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [currentActive, isLoaderExited]);

  return (
    <StyledWrapper>
      <StyledSliderWrapper>
        <StyledSlider>
          <StyledImagesWrapper
            style={{
              transform: `translateX(-${100 * currentActive}%)`,
            }}
          >
            {allFile.nodes.map((image, index) => (
              <StyledImage
                alt=""
                image={getImage(image.childImageSharp)}
                key={index}
              />
            ))}
          </StyledImagesWrapper>
        </StyledSlider>
        <StyledSliderNavigation>
          <StyledSliderThumbsWrapper>
            {allFile.nodes.map((_, index) => (
              <StyledSliderThumb
                key={index}
                $isActive={currentActive === index}
                onClick={() => {
                  handleThumbClick(index);
                }}
              >
                {index <= 8 ? `0${index + 1}` : index}
              </StyledSliderThumb>
            ))}
          </StyledSliderThumbsWrapper>
          <StyledProgress $current={currentActive + 1} $max={count} />
          <StyledArrowsWrapper>
            <StyledArrowButton
              aria-label="Poprzednie zdjęcie"
              onClick={handlePrevClick}
            />
            <StyledArrowButton
              aria-label="Następne zdjęcue"
              direction="right"
              onClick={handleNextClick}
            />
          </StyledArrowsWrapper>
        </StyledSliderNavigation>
      </StyledSliderWrapper>
      <StyledHeadlineWrapper>
        <StyledHeadline
          data-scroll=""
          data-scroll-trigger="header"
          data-scroll-distance="80"
          data-scroll-start="top top"
        >
          <AnimatedTextWrapper>
            <StyledAnimatedText start={!isLoading} delay={0.8}>
              Zamieszkaj na
            </StyledAnimatedText>
          </AnimatedTextWrapper>
          <AnimatedTextWrapper>
            <StyledAnimatedText start={!isLoading} delay={1.0}>
              Osiedlu <span>Zielone</span>
            </StyledAnimatedText>
          </AnimatedTextWrapper>
          <AnimatedTextWrapper>
            <StyledAnimatedText start={!isLoading} delay={1.2}>
              <span>Wzgórza</span>
            </StyledAnimatedText>
          </AnimatedTextWrapper>
        </StyledHeadline>
      </StyledHeadlineWrapper>
      <SocialMediaWrapper>
        <StyledLink
          href={datoCmsSetting.facebook}
          aria-label="Zobacz nas na facebooku"
          initial={{ scale: 0 }}
          animate={!isLoading && { scale: 1 }}
          transition={{ duration: 1, ease: easeInOutCirc, delay: 1 }}
        >
          <Icon icon={facebookIcon} />
        </StyledLink>
        <StyledLink
          href={datoCmsSetting.instagram}
          aria-label="Zobacz nas na Instagramie"
          initial={{ scale: 0 }}
          animate={!isLoading && { scale: 1 }}
          transition={{ duration: 1, ease: easeInOutCirc, delay: 1.2 }}
        >
          <Icon icon={instagramIcon} />
        </StyledLink>
      </SocialMediaWrapper>
      <StyledScrollBottomButton
        isLoading={isLoading}
        delay={0.8}
        onClick={() => {
          smoothScrollbar?.scrollTo
            ? smoothScrollbar?.scrollTo(0, window.innerHeight, 1500, {
                easing: easingFunction,
              })
            : sweetScroll?.to(window.innerHeight);
        }}
      />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    allFile(
      filter: { name: { regex: "/hero/" } }
      sort: { fields: [name], order: [ASC] }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: DOMINANT_COLOR
            layout: FULL_WIDTH
          )
        }
      }
    }

    datoCmsSetting {
      instagram
      facebook
    }
  }
`;

export default Hero;
