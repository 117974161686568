import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { Icon } from "@iconify/react";
import { forestIcon, highwayIcon, houseIcon, gardenIcon } from "@assets/icons";
import AnimatedVideo from "@components/atoms/AnimatedVideo/AnimatedVideo";

const StyledWrapper = styled.section`
  padding: 240px 0 0;
  background: #f4f4f4;
  position: relative;

  @media (max-width: 1024px) {
    padding: 120px 0 0;
  }

  @media (max-width: 720px) {
    padding: 80px 0 0;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: auto;
  display: flex;
  position: relative;

  @media (max-width: 1180px) {
    flex-direction: column;
    max-width: 950px;
    padding-bottom: 220px;
  }

  @media (max-width: 1024px) {
    align-items: center;
    max-width: 700px;
    padding-bottom: 300px;
  }

  @media (max-width: 720px) {
    padding-bottom: 400px;
  }
`;

const StyledContentWrapper = styled.div`
  margin-right: 95px;
  max-width: 550px;

  @media (max-width: 1180px) {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

const StyledHeader = styled.header`
  h3 {
    font-weight: 400;
    font-family: "Cardo", serif;
    font-size: 3.4rem;
    line-height: 1.4;
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 720px) {
    h3 {
      font-size: 2.4rem;
    }
  }
`;

const StyledHeadline = styled.h2`
  line-height: 1.3;
  margin: 0 0 30px;

  @media (max-width: 1024px) {
    text-align: center;
    margin: 0 0 35px;
  }
`;

const StyledRightColumn = styled.div`
  flex: 1;

  @media (max-width: 1180px) {
    margin-top: 40px;
  }
`;

const StyledVideo = styled(AnimatedVideo)`
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: 1700px) {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
`;

const StyledParagraph = styled.p`
  line-height: 2.2;
  margin: 0;

  ${StyledContentWrapper} & {
    margin: 40px 0 0;
  }

  span {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 1180px) {
    ${StyledContentWrapper} & {
      margin: 35px 0 0;
    }
  }
`;

const StyledAttributesWrapper = styled.div`
  margin-top: 40px;
  width: 535px;

  @media (max-width: 1180px) {
    height: 192px;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }

  @media (max-width: 720px) {
    width: 100%;
    bottom: 170px;
  }
`;

const StyledAttributesInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAttributesRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  :first-of-type {
    border-bottom: 2px solid ${({ theme }) => theme.washLighter};
    padding-bottom: 15px;
  }

  :last-of-type {
    padding-top: 15px;
  }

  @media (max-width: 720px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const StyledAttribute = styled.div`
  display: flex;
  width: 48%;
  padding-top: 15px;

  :first-of-type {
    border-right: 2px solid ${({ theme }) => theme.washLighter};
    padding-right: 15px;
  }

  svg {
    font-size: 6.5rem;
    color: ${({ theme }) => theme.primary};
    margin-right: 20px;
  }

  span {
    margin-top: 10px;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    svg {
      margin-right: 0;
    }

    span {
      font-size: 1.4rem;
    }
  }
`;

const AboutEstate = () => {
  const { videos } = useStaticQuery<{
    videos: { childVideoFfmpeg: { webm: any; mp4: any } };
  }>(query);

  return (
    <StyledWrapper id="about_estate">
      <StyledInnerWrapper>
        <StyledContentWrapper>
          <StyledHeader>
            <StyledHeadline data-scroll="">
              Nowe osiedle w Twojej okolicy <span>Osiedle Zielone Wzgórza</span>
            </StyledHeadline>
            <h3 data-scroll="">
              Osiedle powstanie w malowniczej i spokojnej miejscowości -{" "}
              <span>Lewiczyn</span>, niedaleko Mławy.
            </h3>
          </StyledHeader>
          <StyledParagraph data-scroll="">
            Nowe osiedle <span>"Zielone Wzgórza"</span> to miejsce, w którym
            można znaleźć sielankową, zieloną oazę na obrzeżach Mławy. Z
            siłownią na świeżym powietrzu, lokalnym sklepem na osiedlu i{" "}
            <span>gwarancją nowego domu</span> w przystępnych cenach.
          </StyledParagraph>

          <StyledAttributesWrapper>
            <StyledAttributesInnerWrapper data-scroll="">
              <StyledAttributesRow>
                <StyledAttribute>
                  <Icon icon={forestIcon} />
                  <span>
                    Otoczenie <br /> lasu
                  </span>
                </StyledAttribute>
                <StyledAttribute>
                  <Icon icon={highwayIcon} />
                  <span>
                    Niedaleko nowa
                    <br /> droga S7
                  </span>
                </StyledAttribute>
              </StyledAttributesRow>
              <StyledAttributesRow>
                <StyledAttribute>
                  <Icon icon={houseIcon} />
                  <span>
                    Przemyślany rozkład <br />
                    pomieszczeń
                  </span>
                </StyledAttribute>
                <StyledAttribute>
                  <Icon icon={gardenIcon} />
                  <span>
                    Ogródek przynależny
                    <br /> dla każdego lokalu
                  </span>
                </StyledAttribute>
              </StyledAttributesRow>
            </StyledAttributesInnerWrapper>
          </StyledAttributesWrapper>
        </StyledContentWrapper>

        <StyledRightColumn>
          <StyledVideo
            data-scroll=""
            data-scroll-distance="160"
            background="#f4f4f4"
            autoPlay
            muted
            loop
            sources={[
              videos.childVideoFfmpeg.webm,
              videos.childVideoFfmpeg.mp4,
            ]}
          />
          <StyledParagraph data-scroll="" data-scroll-distance="160">
            Kompleks <span>Zielone Wzgórza</span> położony jest w centrum
            Mazowsza. Osiedle położone jest w przepięknym otoczeniu przyrody, z
            dużą ilością drzew, <span>w pobliżu zbiornika Zalew Ruda</span>.{" "}
            <span>Mamy wiele do zaoferowania dla Ciebie i Twojej rodziny</span>.
            Przyjdź i ciesz się naturą, spacerami po lesie, naszymi
            niesamowitymi widokami, i ucieczką od miasta.
          </StyledParagraph>
          <StyledParagraph data-scroll="" data-scroll-distance="160">
            Osiedle zlokalizowane w sąsiedztwie{" "}
            <span>Rezerwatu Zieluńsko-Rzęgnowskiego</span>.
          </StyledParagraph>
        </StyledRightColumn>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    videos: file(name: { eq: "about_estate" }) {
      childVideoFfmpeg {
        webm: transcode(
          outputOptions: ["-crf 20", "-b:v 0"]
          maxWidth: 844
          maxHeight: 668
          fileExtension: "webm"
          codec: "libvpx-vp9"
        ) {
          width
          src
          presentationMaxWidth
          presentationMaxHeight
          originalName
          height
          fileExtension
          aspectRatio
        }
        mp4: transcode(
          maxWidth: 844
          maxHeight: 668
          fileExtension: "mp4"
          codec: "libx264"
        ) {
          width
          src
          presentationMaxWidth
          presentationMaxHeight
          originalName
          height
          fileExtension
          aspectRatio
        }
      }
    }
  }
`;

export default AboutEstate;
