import React, { FC } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Property from "@components/atoms/Property/Property";
import {
  fenceIcon,
  roofIcon,
  foundationIcon,
  photovoltaicIcon,
  brickwallIcon,
  windowIcon,
  gymIcon,
  carIcon,
  shopIcon,
} from "@assets/icons";
import { ChildImageSharp } from "../../../types/queryTypes";

const StyledWrapper = styled.section`
  padding: 100px 0;
  background: #285a53;
  position: relative;
  z-index: 1;
  margin: 180px 0 0;

  @media (max-width: 720px) {
    margin: 90px 0 0;
    padding: 60px 0;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1050px;
  margin: 0 auto;
  color: #fff;
`;

const StyledHeadline = styled.h2`
  width: 100%;
  text-align: center;
  margin: 0 0 80px;
`;

const StyledImage = styled(GatsbyImage)`
  position: absolute !important;
  opacity: 0.4;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: brightness(0.2);
`;

const PropertiesWrapper = styled.div`
  display: grid;
  gap: 90px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 90px 70px;
    max-width: 600px;
  }

  @media (max-width: 540px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 50px 0;
  }
`;

const StyledProperty = styled(Property)``;

const Properties: FC = () => {
  const { file } = useStaticQuery<{ file: ChildImageSharp }>(query);

  return (
    <StyledWrapper data-scroll="" id="properties">
      <StyledInnerWrapper>
        <StyledHeadline>Cechy Inwestycji</StyledHeadline>
        <PropertiesWrapper>
          <StyledProperty icon={fenceIcon}>
            <span>Powierzchnia działki dla </span>
            <span>jednego lokalu wynosi</span> od 260 m<sup>2</sup> do 455 m
            <sup>2</sup>
          </StyledProperty>

          <StyledProperty icon={roofIcon}>
            <span>Dach wykonany z blachy na</span> rąbek w kolorze antracytowym.
          </StyledProperty>

          <StyledProperty icon={foundationIcon}>
            <span>Solidne fundamenty</span> wykonane klasycznie.
          </StyledProperty>

          <StyledProperty icon={photovoltaicIcon}>
            <span>Budynek wyposażony w rekuperację,</span>{" "}
            <span>pompę ciepła i ogrzewanie podłogowe.</span>{" "}
            <span>Możliwość podłączenia fotowoltaiki.</span>
          </StyledProperty>

          <StyledProperty icon={brickwallIcon}>
            <span>Ściany zewnętrzne i wewnętrzne</span> wykonane z bloczka
            komórkowego.
          </StyledProperty>

          <StyledProperty icon={windowIcon}>
            <span>Okna z pakietem trzyszybowym</span> w kolorze antracyt.
          </StyledProperty>

          <StyledProperty icon={gymIcon}>
            <span>Siłownia zewnętrzna dla</span>{" "}
            <span>wszystkich mieszkańców</span> osiedla.
          </StyledProperty>

          <StyledProperty icon={carIcon}>
            <span>Podjazd do budynku wykonany</span> z kostki brukowej.
          </StyledProperty>

          <StyledProperty icon={shopIcon}>
            <span>Sklep osiedlowy dla</span> wszystkich mieszkańców.
          </StyledProperty>
        </PropertiesWrapper>
      </StyledInnerWrapper>

      <StyledImage image={getImage(file.childImageSharp)} alt="" />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "properties" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;

export default Properties;
