import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import AnimatedVideo from "@components/atoms/AnimatedVideo/AnimatedVideo";

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 1540px;
  margin: 260px auto 0;
  display: flex;
  align-items: center;

  @media (max-width: 1180px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    max-width: 900px;
  }

  @media (max-width: 1024px) {
    margin: 120px auto 0;
    align-items: center;
    max-width: 700px;
  }

  @media (max-width: 720px) {
    margin: 80px auto 0;
  }
`;

const StyledVideo = styled(AnimatedVideo)`
  width: 840px;
  margin-right: 100px;

  @media (max-width: 1180px) {
    width: 100%;
    margin-right: 0;
    margin-top: 50px;
  }
`;

const StyledContentWrapper = styled.div`
  max-width: 560px;

  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

const StyledHeadline = styled.h2`
  line-height: 1.4;
  margin: 0;

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const StyledParagraph = styled.p`
  margin: 25px 0 0;
  line-height: 1.8;

  span {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
`;

const Shape = () => {
  const { videos } = useStaticQuery<{
    videos: { childVideoFfmpeg: { webm: any; mp4: any } };
  }>(query);

  return (
    <StyledWrapper>
      <StyledVideo
        autoPlay
        muted
        loop
        sources={[videos.childVideoFfmpeg.webm, videos.childVideoFfmpeg.mp4]}
        data-scroll=""
      />
      <StyledContentWrapper data-scroll="">
        <StyledHeadline>Oryginalny kształt budynków</StyledHeadline>
        <StyledParagraph>
          Osiedle ma wiele powodów, dla których warto w nim zamieszkać. <br />
          Wszystkie mieszkania są <span>
            parterowe i o oryginalnej bryle
          </span>. <br />
          Każdy budynek się wyróżnia, a każdy dom ma przemyślany rozkład
          pomieszczeń. Każdy budynek ma <span>inną osobowość</span>, sprawdź,
          który pasuje do <span>Ciebie!</span>
        </StyledParagraph>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "hero2" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: DOMINANT_COLOR
          layout: FULL_WIDTH
        )
      }
    }

    videos: file(name: { eq: "shape" }) {
      childVideoFfmpeg {
        webm: transcode(
          outputOptions: ["-crf 20", "-b:v 0"]
          maxWidth: 844
          maxHeight: 668
          fileExtension: "webm"
          codec: "libvpx-vp9"
        ) {
          width
          src
          presentationMaxWidth
          presentationMaxHeight
          originalName
          height
          fileExtension
          aspectRatio
        }
        mp4: transcode(
          maxWidth: 844
          maxHeight: 668
          fileExtension: "mp4"
          codec: "libx264"
        ) {
          width
          src
          presentationMaxWidth
          presentationMaxHeight
          originalName
          height
          fileExtension
          aspectRatio
        }
      }
    }
  }
`;

export default Shape;
