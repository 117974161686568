import { motion, useAnimation } from "framer-motion";
import { easeInOutCirc } from "@theme/easing";
import React, { FC, useEffect } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  cursor: pointer;
`;

const ScrollBottomButton: FC<Props> = ({
  isLoading,
  delay,
  onClick,
  className,
}) => {
  const controls = useAnimation();
  const svgControls = useAnimation();

  const sequence = async () => {
    await controls.start({ y: 0, opacity: 1 });
    await svgControls.start({
      y: "10%",
    });
  };

  useEffect(() => {
    if (!isLoading) {
      sequence();
    }
  }, [isLoading]);

  return (
    <StyledButton
      onClick={onClick}
      className={className}
      aria-label="Zeskroluj stronę"
    >
      <motion.svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        animate={svgControls}
        transition={{
          repeat: Infinity,
          duration: 2,
          ease: easeInOutCirc,
          repeatType: "mirror",
        }}
      >
        <motion.path
          d="M34.9781 1.37808C34.207 1.39448 33.589 2.02886 33.6 2.79995V63.3554L21.9679 52.1937C21.7 51.9257 21.3335 51.7781 20.9562 51.7835C20.3875 51.7999 19.8789 52.1554 19.6765 52.6859C19.4742 53.2164 19.6109 53.8179 20.032 54.2062L35 68.5781L49.9679 54.2062C50.3343 53.8617 50.4875 53.3476 50.3671 52.8609C50.2468 52.3742 49.875 51.9859 49.3937 51.8492C48.907 51.707 48.3875 51.8382 48.032 52.1937L36.4 63.3554V2.79995C36.4054 2.42261 36.2578 2.0562 35.9898 1.78823C35.7218 1.52026 35.3554 1.37261 34.9781 1.37808Z"
          fill="#fff"
          transition={{ duration: 2, ease: easeInOutCirc, delay }}
          initial={{
            y: "-70%",
            opacity: 0,
          }}
          animate={controls}
        />
      </motion.svg>
    </StyledButton>
  );
};

interface Props {
  isLoading: boolean;
  delay: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export default ScrollBottomButton;
