import React, { FC, useContext, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Pagination } from "swiper";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ArrowButton from "@components/atoms/ArrowButton/ArrowButton";
import { cssEaseOutExpo } from "@theme/easing";
import { filterLightboxImages } from "@utils/filterLightboxImages";
import { LightboxContext } from "@context/index";
import { ChildImageSharp } from "../../../../types/queryTypes";

SwiperCore.use([Navigation, Thumbs, Pagination]);

const StyledWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  .swiper {
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }

  .swiper-wrapper {
    display: inline-flex;
    align-items: center;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 42vw;

  @media (max-width: 720px) {
    height: 60vw;
  }
`;

const StyledBottomWrapper = styled.div`
  height: 105px;
  padding: 40px 5% 30px;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const StyledArrowButton = styled(ArrowButton)`
  font-size: 2.6rem;

  @media (max-width: 720x) {
    font-size: 2.2rem;
  }
`;

const StyledThumbsWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70%;
  max-width: 960px;
  overflow: hidden;

  .swiper-slide {
    filter: brightness(60%);
    transition: filter 0.5s ${cssEaseOutExpo};
  }

  .swiper-slide-thumb-active {
    filter: brightness(100%);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledThumbImage = styled(GatsbyImage)`
  width: 300px;
  height: 180px;
  cursor: pointer;
`;

const StyledCounter = styled.div`
  margin: 0 20px;
  font-weight: 600;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.primaryDarker};
  display: flex;
  justify-content: center;
  width: 60px;

  @media (max-width: 720px) {
    font-size: 1.8rem;
  }
`;

const Gallery: FC<Props> = ({ className }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
  const { visualizationsLightbox } = useContext(LightboxContext);
  const { allFile, lightbox } = useStaticQuery<{
    allFile: { nodes: ChildImageSharp[] };
    lightbox: { nodes: ChildImageSharp[] };
  }>(query);

  useLayoutEffect(() => {
    visualizationsLightbox.setImages(
      filterLightboxImages(lightbox.nodes).map((lightboxImage) => ({
        src: lightboxImage,
        loading: "lazy",
        alt: "",
      }))
    );
  }, []);

  const onImageClick = (index: number) => {
    visualizationsLightbox.setCurrentImage(index);
    visualizationsLightbox.setLightboxActive(true);
  };

  const onSlideChange = (swiper: SwiperCore) => {
    visualizationsLightbox.setCurrentImage(swiper.realIndex);
    thumbsSwiper?.update();
  };

  return (
    <StyledWrapper className={className} data-scroll="">
      <Swiper
        grabCursor
        updateOnWindowResize
        slidesPerView={1}
        spaceBetween={80}
        loop
        speed={1000}
        navigation={{
          prevEl: "#visualizations-prev-button",
          nextEl: "#visualizations-next-button",
        }}
        thumbs={{
          swiper: thumbsSwiper,
        }}
        onSwiper={(swiper) => visualizationsLightbox.setSwiper(swiper)}
        onSlideChange={onSlideChange}
      >
        {allFile.nodes.map((child, index) => (
          <SwiperSlide key={index}>
            <StyledImage
              image={getImage(child.childImageSharp)}
              alt=""
              onClick={() => onImageClick(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledBottomWrapper>
        <StyledArrowButton
          aria-label="Poprzednie zdjęcie"
          id="visualizations-prev-button"
        />
        <StyledCounter>
          {visualizationsLightbox.currentImage + 1} / {allFile.nodes.length}
        </StyledCounter>
        <StyledArrowButton
          aria-label="Następne zdjęcie"
          direction="right"
          id="visualizations-next-button"
        />

        <StyledThumbsWrapper>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={30}
            slidesPerView="auto"
            freeMode
            watchSlidesProgress
            updateOnWindowResize
            speed={1000}
          >
            {allFile.nodes.map((child, index) => (
              <SwiperSlide key={index}>
                <StyledThumbImage
                  image={getImage(child.childImageSharp)}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledThumbsWrapper>
      </StyledBottomWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    allFile(
      filter: { name: { regex: "/visualization_/" } }
      sort: { fields: [name], order: [ASC] }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }

    lightbox: allFile(
      filter: { name: { regex: "/visualization_/" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90, formats: JPG, layout: FIXED)
        }
      }
    }
  }
`;

interface Props {
  className?: string;
}

export default Gallery;
